import React, { useState, useEffect, useContext } from "react";
import { Doughnut } from "react-chartjs-2";
import DropDown from "../../common/dropDown";
import { createGradientColor, getData } from "../../common/utils";
import { AppContext } from "./../../App";
import SpinnerInfinity from "./../../common/spinner";
import BounceLoader from "react-spinners/BounceLoader";
import { ReactComponent as NoDataSVG } from "./../../assets/noDataSVG.svg";
import u from "./../../assets/noData/Web placeholders (Analytics 2).png";

const exerciseEquipmentTypeDict = require("../../assets/JSON/exercise_data.json");

const options = ["Reps", "Sets", "Volume"];

const doughLabelsLine = {
  id: "doughLabelsLine",
  afterDraw(chart) {
    const {
      ctx,
      chartArea: { width, height },
    } = chart;

    const cx = chart._metasets[0].data[0].x;
    const cy = chart._metasets[0].data[0].y;

    const sum = chart.data.datasets[0].data.reduce((a, b) => a + b, 0);

    chart.data.datasets.forEach((dataset, i) => {
      chart.getDatasetMeta(i).data.forEach((arc, index) => {
        const oR = arc.outerRadius;
        const s = arc.startAngle;
        const e = arc.endAngle;
        const halfAngle = (s + e) / 2;
        const xO = arc.x + Math.cos(halfAngle) * oR;
        const yO = arc.y + Math.sin(halfAngle) * oR;
        const x = xO;
        const y = yO;

        const halfwidth = width / 2;
        const halfheight = height / 2;
        const xLine = x >= halfwidth ? x + 17 : x - 17;
        const yLine = y >= halfheight ? y + 17 : y - 17;

        const extraLine = x >= halfwidth ? 16 : -16;
        const plusFivePx = x >= halfwidth ? 7 : -7;

        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.arc(x, y, 2, 0, 2 * Math.PI, true);
        ctx.fill();
        ctx.moveTo(x, y);
        ctx.lineTo(xLine, yLine);
        ctx.lineTo(xLine + extraLine - plusFivePx, yLine);
        ctx.strokeStyle = "#d1d1d1";
        ctx.stroke();

        // text
        const textWidth = ctx.measureText(chart.data.labels[index]).width;
        ctx.font = "bold 1.25rem Arial"; // control the position
        const textXPosition = x >= halfwidth ? "left" : "right";
        ctx.textAlign = textXPosition;
        ctx.textBaseline = "middle";
        // ctx.fillStyle = dataset.backgroundColor[index];
        ctx.fillStyle = "#353535";

        ctx.fillText(
          `${((chart.data.datasets[0].data[index] * 100) / sum).toFixed(0)}%`,
          xLine + extraLine - plusFivePx,
          yLine + 1
        );
        ctx.font = "bold 0.6rem Arial"; // Set font size and style
        // ctx.textAlign = 'left'; // Set text alignment
        ctx.textBaseline = "middle"; // Set baseline alignment
        ctx.fillStyle = "#353535"; // Set text color
        const maxWidth = 10; // Maximum width for the text
        const text = chart.data.labels[index]; // Text to display
        let lines = [];
        let currentLine = "";
        const words = text.split(" ");
        words.forEach((word) => {
          const testLine = currentLine + word + " ";
          const metrics = ctx.measureText(testLine);
          const testWidth = metrics.width;
          if (testWidth > maxWidth && currentLine !== "") {
            lines.push(currentLine);
            currentLine = word + " ";
          } else {
            currentLine = testLine;
          }
        });

        lines.push(currentLine); // Push the last line

        // Draw each line
        lines.forEach((line, i) => {
          ctx.fillText(
            line,
            xLine + extraLine - plusFivePx,
            yLine + 14 + i * 20
          ); // Adjust Y position for each line
        });
      });
    });
  },
};

function EquipmentTypeChart(props) {
  const { gym_id, month, year } = props;

  const [chartData, setChartData] = useState(null);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const [isLoading, setIsLoading] = useState(true);
  const { appInfo } = useContext(AppContext);
  const [modifiedData, setModifiedData] = useState(null);
  const   override = { top: '7rem',
    left: '0rem'} ;

  useEffect(() => {
    setModifiedData(null);
    setIsLoading(true);
  }, [gym_id, month, year]);

  useEffect(() => {
    fetchData();
  }, [selectedOption, modifiedData]);

  const handleDropDownChange = (sO) => {
    setSelectedOption(options[sO]);
  };

  const fetchData = async () => {
    try {
      let modifiedResult = null;

      if (modifiedData == null) {
        const data1 = await getData(
          `usage?month=${month}&year=${year}&gym_id=${gym_id}`,
          appInfo.token
        );

        const { result } = data1;
        modifiedResult = result.filter((item) => {
          const exercise = exerciseEquipmentTypeDict.find(
            (ex) => ex.exercise_identifier === item.engine_exercise_name
          );
          return !(exercise?.pinPlate === "cardio" || exercise?.pinPlate === "interval");
        }).map((item) => {
          const exercise = exerciseEquipmentTypeDict.find(ex => ex.exercise_identifier === item.engine_exercise_name)
          return {
          ...item,
          equipmentType:exercise?.equipmentType ?? 'unknown',
                }});

        setModifiedData(modifiedResult);
      } else {
        modifiedResult = modifiedData;
      }

      const filteredResult = modifiedResult.filter(
        (item) => item[selectedOption.toLowerCase()]
      );

      const groupedResult = filteredResult.reduce((groups, item) => {
        const key = item.equipmentType;
        if (!groups[key]) {
          groups[key] = 0;
        }
        groups[key] += item[selectedOption.toLowerCase()];
        return groups;
      }, {});
      const labels = Object.keys(groupedResult);
      const data = Object.values(groupedResult);
      const processedData = {
        labels,
        datasets: [
          {
            data,
            borderAlign: "inner",
            borderWidth: 1,
            hoverBorderWidth: 4,
          },
        ],
      };
      setChartData(processedData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching workouts:", error);
    }
  };

  const optionale = {
    responsive: true,
    aspectRatio: 1,
    plugins: {
      id: "custom_labels",
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    layout: {
      padding: {
        bottom: 80,
        top: 10,
        left: 35,
      },
    },
    cutout: "65%",
    radius: "75%",
    animation: {
      animateRotate: true,
      animateScale: true,
    },
    backgroundColor: (context) => {
      //return createGradientColor(context, appInfo);
      return appInfo.org.branding_colors[1];
    },
  };

  return (
    <div className="chartCard">
      <div className="z-0 chartHeader flex justify-between">
        <p className="chartTitle text-gray-600 italic font-semibold">
          Equipment Type
        </p>
        <DropDown options={options} onSelect={handleDropDownChange} />
      </div>
      <div className="doughnut-canvas-container w-96 h-80 flex justify-center">
        {isLoading ? (
          <BounceLoader
          color={`#${appInfo?.org?.branding_colors[0] ?? "#FC5A02"}`}          loading={isLoading}
          cssOverride={override}
          size={'4rem'}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
        ) : modifiedData != null && modifiedData.length > 0 ? (
          <Doughnut
            data={chartData}
            options={optionale}
            plugins={[doughLabelsLine]}
          />
        ) : (
          <div className="flex flex-col" style={{ alignItems: "center" }}>
            <img src={u} alt="logo" className="h-64" />
            <div className="text-gray-300">No data is available</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EquipmentTypeChart;
